$.arrayIntersect = function (a, b) {
  return $.grep(a, function (i) {
    return $.inArray(i, b) > -1;
  });
};

$.uniqueArray = function (array) {
  let unique_arr = [];
  array.forEach(function (i, e) {
    if (unique_arr.indexOf(i) === -1) unique_arr.push(i);
  });
  return unique_arr;
}

//Browser check, Internet explorer(6-7-8-9-10-11) not support play and pause on slick.js
const isIEbrowser = function () {
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
    return true;
  } else {
    return false;
  }
}

const isMobile = () => {
  let intViewportWidth = window.innerWidth;
  if (!intViewportWidth || !screenWidth.mobile) {
    return false;
  }
  if (intViewportWidth <= screenWidth.mobile) {
    return true;
  }
  return false;
}

const isTouch = () => {
  let intViewportWidth = window.innerWidth;
  if (!intViewportWidth || !screenWidth.desktop) {
    return false;
  }
  if (intViewportWidth < screenWidth.desktop) {
    return true;
  }
  return false;
}

const isDesktop = () => {
  let intViewportWidth = window.innerWidth;
  if (!intViewportWidth || !screenWidth.desktop) {
    return false;
  }
  if (intViewportWidth >= screenWidth.desktop) {
    return true;
  }
  return false;
}

const removeURLParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);
    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}
 